<template>
    <div class="campus-health">
        <heads></heads>
        <img class="top-bg" src="../../assets/images/campus_health_top_bg.png" alt="">
        <div class="campus-health-container">
            <div class="content" v-if="articleCategory.name != null && articleCategory.name != ''">
                <div class="introduce">
                    <p>{{ articleCategory.description }}</p>
                </div>
                <div class="display-or-list" :class="articleCategory.is_school == 1 ? 'row-normal' : 'row-reverse'"
                    :style="{
                        backgroundImage: 'url(' + articleCategory.video_background + ')',
                    }">
                    <div class="video" v-if="articleCategory.is_school != 1">
                        <div class="video-box">
                            <vue-aliplayer-v2 v-if="options.vid != ''" ref="VueAliplayerV2" :options="options" />
                        </div>
                        <h2>{{ videoName }}</h2>
                    </div>
                    <div v-else class="school-list"
                        :class="articleCategory.children.length > 6 ? 'column-gap-100' : 'column-gap-120'">
                        <router-link class="school-list-item" v-for="(item, index) in articleCategory.children " :to="item.link_url != '' && item.link_url != null ? item.link_url : {
                            path: '/campusHealth/category/list',
                            query: { id: item.id }
                        }">
                            <img :src="item.icon" alt="">
                            <span class="title">{{ item.name }}</span>
                        </router-link>
                    </div>
                </div>
                <div class="category-navs">
                    <div class="category-navs-item" v-for="(item, index) in videoList ">
                        <router-link :to="{
                            path: '/campusHealth/video/detail',
                            query: { id: item.id }
                        }">
                            <span class="title"
                                :class="item.id == 12 ? 'font-size-18' : item.id == 312 ? 'font-size-20' : ''">{{
                                    item.name }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import VueAliplayerV2 from "vue-aliplayer-v2";
import { getArticleCategory, getVideoList, getVideoPlayAuth } from "@api/public";
export default {
    components: {
        heads,
        foot,
        VueAliplayerV2
    },
    data() {
        return {
            videoList: [],
            articleCategory: { children: [] },
            options: {
                autoplay: false,
                height: "100%",
                playsinline: true,
                vid: "",
                playauth: "",
                cover: "",
                encryptType: 1,
            },
            videoName: ""
        };
    },
    watch: {
        $route(to, from) {
            this.$router.go(0);
        },
    },
    mounted: function () {
        let that = this;
        getArticleCategory({ id: 12 }).then(function (res) {
            that.$set(that, "articleCategory", res.data);
            if (res.data.course_vid != "" && res.data.course_vid != null) {
                that.$set(that.options, "vid", res.data.course_vid);
                getVideoPlayAuth({ course_vid: res.data.course_vid, region: 1 }).then((res) => {
                    that.options.playauth = res.data.videoPlayAuth.playAuth;
                    let title = res.data.videoPlayAuth.videoMeta.title;
                    that.videoName = title.substring(0, title.lastIndexOf("."))
                    that.$refs.VueAliplayerV2.play();
                })
            }
        });
        getVideoList({ course_cate_id: 38, limit: 30 }).then(function (res) {
            let videoListTemp = res.data.list.map((item) => {
                if ((item.name).indexOf("《") == -1) {
                    item.name = "《" + item.name;
                }
                if ((item.name).lastIndexOf("》") == -1) {
                    item.name = item.name + "》";
                }
                return item;
            })
            that.$set(that, "videoList", videoListTemp);
        });
    },
    created: function () {
        document.title = this.$route.meta.title;
    },
};
</script>

<style scoped>
.campus-health {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #3a657d;
}

.campus-health .top-bg {
    width: 1920px;
    height: 470px;
}

.campus-health .campus-health-container {
    position: relative;
    width: 1920px;
    height: auto;
    top: -205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content {
    position: relative;
    width: 960px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.campus-health .campus-health-container .content .introduce {
    position: relative;
    width: 100%;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .introduce p {
    position: relative;
    height: auto;
    max-height: 205px;
    font-size: 26px;
    overflow-y: auto;
    font-family: kaiti;
    text-indent: 2em;
    color: white;
    word-break: break-all;
    text-align: justify;
    margin-left: 70px;
    margin-right: 76px;
}

.campus-health .campus-health-container .content .display-or-list {
    position: relative;
    width: 100%;
    height: 540px;
    background-size: 960px 540px;
    display: flex;
    align-items: center;
}

.campus-health .campus-health-container .content .display-or-list.row-normal {
    flex-direction: row;
    justify-content: center;
}


.campus-health .campus-health-container .content .display-or-list.row-reverse {
    flex-direction: row-reverse;
}

.campus-health .campus-health-container .content .display-or-list .video {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 80px;
}

.campus-health .campus-health-container .content .display-or-list .video .video-box {
    position: relative;
    width: 485px;
    height: 367px;
    background: url(../../assets/images/campus_health_video_play_border.jpg) 485px 367px no-repeat;
    background-size: cover;
    background-position: center 0;
    /* border: 1px solid red; */
}

.campus-health .campus-health-container .content .display-or-list .video .video-box .prism-player {
    position: relative;
    left: 30px;
    top: 30px;
    height: 84% !important;
    width: 88% !important;
}

.campus-health .campus-health-container .content .display-or-list .video h2 {
    position: relative;
    font-size: 24px;
    font-family: kaiti;
    font-weight: bold;
    margin-top: 15px;
    /* border: 1px solid red; */
}

.campus-health .campus-health-container .content .category-navs {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.campus-health .campus-health-container .content .display-or-list .school-list {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;

}

.campus-health .campus-health-container .content .display-or-list .school-list.column-gap-100 {
    width: 80%;
    column-gap: 100px;
}

.campus-health .campus-health-container .content .display-or-list .school-list.column-gap-120 {
    width: 60%;
    column-gap: 120px;
}

.campus-health .campus-health-container .content .display-or-list .school-list .school-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .display-or-list .school-list .school-list-item img {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.campus-health .campus-health-container .content .display-or-list .school-list .school-list-item .title {
    position: relative;
    font-family: kaiti;
    font-weight: bold;
    font-size: 22px;
    margin-top: 15px;
}

.campus-health .campus-health-container .content .category-navs .category-navs-item {
    position: relative;
    width: 153px;
    height: 43px;
    background: url(../../assets/images/campus_health_category_title_bg.png) 153px 43px no-repeat;
    background-size: cover;
    background-position: center 0;
}

.campus-health .campus-health-container .content .category-navs .category-navs-item a {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .category-navs .category-navs-item a .title {
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: #2a4f47;
    font-family: kaiti;
    font-size: 22px;
    font-weight: bold;
}

.campus-health .campus-health-container .content .category-navs .category-navs-item a .title.font-size-18 {
    font-size: 18px !important;
}

.campus-health .campus-health-container .content .category-navs .category-navs-item a .title.font-size-20 {
    font-size: 20px !important;
}
</style>