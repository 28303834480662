var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-health"},[_c('heads'),_c('img',{staticClass:"top-bg",attrs:{"src":require("../../assets/images/campus_health_top_bg.png"),"alt":""}}),_c('div',{staticClass:"campus-health-container"},[(_vm.articleCategory.name != null && _vm.articleCategory.name != '')?_c('div',{staticClass:"content"},[_c('div',{staticClass:"introduce"},[_c('p',[_vm._v(_vm._s(_vm.articleCategory.description))])]),_c('div',{staticClass:"display-or-list",class:_vm.articleCategory.is_school == 1 ? 'row-normal' : 'row-reverse',style:({
                    backgroundImage: 'url(' + _vm.articleCategory.video_background + ')',
                })},[(_vm.articleCategory.is_school != 1)?_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-box"},[(_vm.options.vid != '')?_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}}):_vm._e()],1),_c('h2',[_vm._v(_vm._s(_vm.videoName))])]):_c('div',{staticClass:"school-list",class:_vm.articleCategory.children.length > 6 ? 'column-gap-100' : 'column-gap-120'},_vm._l((_vm.articleCategory.children),function(item,index){return _c('router-link',{staticClass:"school-list-item",attrs:{"to":item.link_url != '' && item.link_url != null ? item.link_url : {
                        path: '/campusHealth/category/list',
                        query: { id: item.id }
                    }}},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.name))])])}),1)]),_c('div',{staticClass:"category-navs"},_vm._l((_vm.videoList),function(item,index){return _c('div',{staticClass:"category-navs-item"},[_c('router-link',{attrs:{"to":{
                        path: '/campusHealth/video/detail',
                        query: { id: item.id }
                    }}},[_c('span',{staticClass:"title",class:item.id == 12 ? 'font-size-18' : item.id == 312 ? 'font-size-20' : ''},[_vm._v(_vm._s(item.name))])])],1)}),0)]):_vm._e()]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }